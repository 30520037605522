import React from 'react';
import useLocale from '../../hooks/useLocale';
import Button from '../Button';
import { Input } from '../FormComponents';
import { H2, H3 } from '../Headings';
import { toast } from 'react-toastify';

export function ExchangePeriodsConfig({config, handleChange, handleCheckboxChange, addPeriod, deletePeriod}) {
  const {l} = useLocale()
  const fromRef = React.useRef(null)
  const toRef = React.useRef(null)

  function onClick() {
    const {value: from} = fromRef.current;
    const {value: to} = toRef.current;

    if (new Date(from) > new Date(to)) return toast.warning(l('from_cant_be_greater_than_to'))

    addPeriod(from, to);

    fromRef.current.value = '';
    toRef.current.value = '';
  }

  return (
    <>
      <H2>
        <img src='/img/exchange.svg' className={'w-[25px]'} alt='' />{' '}
        {l('exchange_period_config')}
      </H2>
      <div className="grid grid-cols-2">
        <div>
          <div className='flex gap-2'>
            <Input label={l('from')} reference={fromRef} className='max-w-sm' type='date' />
            <Input label={l('to')} reference={toRef} className='max-w-sm' type='date' />
            <Button onClick={onClick} className='self-end mb-2'>{l('add')}</Button>
          </div>

          <H3>{l('periods')}:</H3>
          {config.exchangePeriods.dates.map((date, index) => (
            <p className='my-2'>{l('from')} {date.from.toLocaleDateString()} {l('to')} {date.to.toLocaleDateString()} <Button onClick={() => deletePeriod(index)} type="danger">{l('delete')}</Button></p>
          ))}
        </div>

        <div>
          <Input 
            type='number'
            label={l('num_of_exchanges_per_period')}
            name={'numOfExchangesPerPeriod'}
            onChange={handleChange}
            value={config.exchangePeriods.numOfExchangesPerPeriod}
          />
        </div>
      </div>
    </>
  )
}