import { FileInput, Input, Select } from '../../../components/FormComponents';
import Editor from '../../../components/Editor'
import useLocale from '../../../hooks/useLocale';
import React from 'react';
import Button from '../../../components/Button';
import useForms from '../../../hooks/useForms';

const defaultState = {
    title: '',
    subtitle: '',
    icon: '',
    color: '#FFFFFF',
    form: '',
  }

export function ActiveFormsForm({onSubmit, initState = defaultState}) {
  const {l} = useLocale();
  const {forms} = useForms({paginate: false})
  const [state, setState] = React.useState(initState)
  console.log(state);

  function handleChange(e) {
    const {name, value} = e.target

    setState(old => ({
      ...old,
      [name]: value
    }))
  }

  function handleEditorChange(name, data) {
    setState(old => ({
      ...old,
      [name]: data.getData()
    }))
  }

  async function handleFileUpload(e) {
    const file = e.target.files[0]

    const fd = new FormData()
    fd.append('upload', file)

    const res = await window.fetch(`${process.env.REACT_APP_API_URL}images`, {
      method: 'POST',
      body: fd,
      credentials: 'include'
    })
    const data = await res.json()

    setState(old => ({
      ...old,
      icon: data.url
    }))
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-2">
        <Select label={l('form')} name={'form'} onChange={handleChange} options={forms} labelKey='name' valueKey='_id' value={state.form} />
        <FileInput label={l('icon')} onChange={handleFileUpload} />
        <Input
          label={l('color')}
          type='color'
          name='color'
          value={state.color}
          onChange={handleChange}
        />
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <label htmlFor="" className='font-bold'>{l('title')}</label>
          <Editor value={state.title} handleChange={(_, data) => handleEditorChange('title', data)} />
        </div>
        <div>
          <label htmlFor="" className='font-bold'>{l('subtitle')}</label>
          <Editor value={state.subtitle} handleChange={(_, data) => handleEditorChange('subtitle', data)} />
        </div>
      </div>

      <Button onClick={() => onSubmit(state)}>
        {l('save')}
      </Button>
    </>
  )
}